  
  .container {
  max-width: 70%;
  margin: 30px auto; /* Centra el contenido horizontalmente y agrega espacio superior e inferior */
  padding: 20px;
  
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.70); 
  transition: box-shadow 0.3s ease-in-out;  
  margin-top: 10%;
    
  }
 


  .grid {
    display: grid;
    grid-template-columns: 19% 81%; 
    
    
  }
  .element1{
    grid-column: 1/4; 
    border: solid 1px rgb(14, 13, 13);
    background: rgb(50, 49, 49);
    color: white;
  }

  .menuSuperior{
    grid-column: 1/3; 
  
    
    color: rgb(22, 21, 21);
  }
  
  .tabla {
    border: 2px solid black; /* Agrega un borde sólido de 1px de ancho en color negro */
    padding: 8px; /* Agrega relleno para separar el contenido del borde */
    text-align: left; /* Alinea el texto del encabezado a la izquierda */
  }
  .encabezado {
    border-left: 1px solid black; /* Borde izquierdo sólido de 1px de ancho en color negro */
    border-right: 1px solid black; /* Borde derecho sólido de 1px de ancho en color negro */
    padding: 8px; /* Agrega relleno para separar el contenido del borde */
    text-align: left; /* Alinea el texto del encabezado a la izquierda */
  }
  .label {
    display: block;
    margin-bottom: 8px;
    color: #fff; /* Cambia el color de la etiqueta a blanco */
    font-weight: bold; /* Añade negrita a la etiqueta */
  }
  
  .input {
    width: 80%; /* Ancho del input al 100% para ocupar el contenedor */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 15px; /* Espacio inferior para separar los inputs */
    
  }

  .inputSelect {
    width: 80%; /* Ancho del input al 100% para ocupar el contenedor */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 15px; /* Espacio inferior para separar los inputs */
    
  }
  
  .submit {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: 2px solid #0a0909;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px; /* Reduce el espacio superior para evitar exceso de separación */
    display: inline-block; /* Hace que el botón sea un elemento en línea */
  }

  .submit:hover {
    background-color: #f17070;
  }
  
  
  