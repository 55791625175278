.grid {
    display: grid;
    grid-template-columns: 19% 81%;
    
  
  }

  .element1{
    grid-column: 1/4; 
    background-color: #000000;
    
  }

  .containerGrafico{
    width: 90%;
    height: 90%;
    margin: 5%;

    display: grid;
    grid-template-columns: 50% 50%;
  }