.grid {
  display: grid;
  grid-template-columns: 19% 81%;
}

.container {
    margin-top: 10%;
    width: 90%;
    
  }

  .searchInput{
    margin: 5px;
  }

  .tablacontainer {
    display: flex;
    justify-content: center;
    align-items: center;
   
}
  
  .tabla {
    border: 2px solid black; /* Agrega un borde sólido de 1px de ancho en color negro */
    padding: 8px; /* Agrega relleno para separar el contenido del borde */
    text-align: left; /* Alinea el texto del encabezado a la izquierda */
    min-width: 160px;
  }
  .encabezado {
    border-left: 1px solid black; /* Borde izquierdo sólido de 1px de ancho en color negro */
    border-right: 1px solid black; /* Borde derecho sólido de 1px de ancho en color negro */
    padding: 8px; /* Agrega relleno para separar el contenido del borde */
    text-align: left; /* Alinea el texto del encabezado a la izquierda */
  }
  .label {
    display: block;
    margin-bottom: 8px;
    color: #fff; /* Cambia el color de la etiqueta a blanco */
    font-weight: bold; /* Añade negrita a la etiqueta */
  }
  
  .input {
    width: 100%; /* Ancho del input al 100% para ocupar el contenedor */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 15px; /* Espacio inferior para separar los inputs */
    
  }
  
  .submit {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: 2px solid #0a0909;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px; /* Reduce el espacio superior para evitar exceso de separación */
    display: inline-block; /* Hace que el botón sea un elemento en línea */
  }

  .submit:hover {
    background-color: #f17070;
  }
  
  
  /* Estilos para la paginación */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.pageButton {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  color: #333;
  font-weight: bold;
}

.pageButton:hover {
  background-color: #ddd;
  color: #000;
}

.activePage {
  background-color: #4CAF50;
  color: white;
  border-color: #4CAF50;
}

.pageButton:disabled {
  background-color: #eaeaea;
  color: #ccc;
  cursor: not-allowed;
}

/* Para las celdas sin datos */
.noData {
  text-align: center;
  font-style: italic;
  color: #666;
}
